import React from "react";
import { Link } from "react-router-dom";
export const TIME_BEFORE_DELIVERY = [
  0, 0.25, 0.5, 0.75, 1, 2, 3, 4, 6, 12, 24, 48, 72,
];
export const ORDER_LIFE_TIME = [
  0.25, 0.5, 0.75, 1, 2, 3, 4, 6, 12, 24, 48, 72, 96, 120, 144, 168, 192,
];
export const SUCCESS_UPDATE_MESSAGE = "labels?.pages?.clients?.successUpdate";
export const regexNumber = /^[+-]?\d+(\.\d+)?$/;
export const UserRole = {
  ADMIN: "ADMIN",
  STORE: "FLORIST",
};
export const DeliveryApi = {
  STUART: "STUART",
  YPER: "YPER",
};
export const COMMAND_PAYMENT_STATE = {
  paid: "PAID",
  unpaid: "UNPAID",
  revoked: "REVOCKED",
  reimbursed: "REMBOURSED",
  waitingPayment: "WAITING_PAID",
};
export const componentSearchType = {
  multilist: "multilist",
  dataSearch: "dataSearch",
  toggleSearch: "toggleSearch",
  multiDropdownList: "multiDropdownList",
  dataController: "dataController",
  slider: "slider",
  datePicker: "datePicker",
  slider: "slider",
};
export const ComponentFilterElastic = {
  MultiListGraph: "MultiListGraph",
  DateRangeSearch: "DateRangeSearch",
  DataSearchGraph: "DataSearchGraph",
  ReactSliderGraph: "ReactSliderGraph",
  ToggleFilterGraph: "ToggleFilterGraph",
};
export const COMMAND_STATUS = {
  PENDING: "Nouvelle",
  APPROVED: "En cours",
  READY: "Prête",
  REVOKED: "Refusée",
  DELIVERING: "En livraison",
  DELIVERED: "Terminée",
  CANCELED: "Annulée",
  LIVRED: "Livré",
};
export const DeliveryModeLabel = {
  FREE: "Drive",
  PROGRAMED: "Programmé",
  EXPRESS: "Livraison à domicile",
};
export const DeliveryMode = {
  FREE: "FREE",
  PROGRAMED: "PROGRAMED",
  EXPRESS: "EXPRESS",
};
export const MomentFormat = {
  DATE_HOURS: "DD/MM/YYYY HH:mm",
  DATE: "DD/MM/YYYY",
  TIME: "HH:mm",
  FULL: "DD MMMM à HH:mm",
};
export const Routes = {
  login: "/login",
  home: "/",
  commands: "/commandes",
  products: "/produits",
  myProducts: "/my-products",
  detailProduct: "/produits/",
  requests: "/product-requests",
  addRequest: "request-add-product",
  detailRequest: "product-request-detail",
  pros: "/pros",
  addStore: "/add-store",
  clients: "/clients",
};
export const DominantColors = [
  {
    name: "colorDominant",
    label: "JAUNE",
    value: "yellow",
    color: "#FFF033",
    textColor: "#000",
  },
  {
    name: "colorDominant",
    label: "ROUGE",
    value: "red",
    color: "red",
    textColor: "white",
  },
  {
    name: "colorDominant",
    label: "BLANC",
    value: "white",
    color: "#fff",
    textColor: "#000",
  },
  {
    name: "colorDominant",
    label: "ROSE",
    value: "pink",
    color: "#FFC0CB",
    textColor: "#000",
  },
  {
    name: "colorDominant",
    label: "VIOLET",
    value: "purple",
    color: "purple",
    textColor: "white",
  },
  {
    name: "colorDominant",
    label: "ORANGE",
    value: "orange",
    color: "#FFA500",
    textColor: "white",
  },
];
export const PaymentModes = {
  CASH: "Espèces",
  STRIPE: "Carte Bancaire",
  PAYMEE: "Paymee",
};
export const PaymentModesVALUE = {
  CASH: "CASH",
  STRIPE: "STRIPE",
  CB: "STRIPE",
  PAYMEE: "PAYMEE",
};
export const PromotionType = {
  POURCENT: "POURCENT",
  FIXED_AMOUNT: "FIXED_AMOUNT",
};
export const DiscountType = {
  AUTOMATIC_DISCOUNT: "AUTOMATIC_DISCOUNT",
  CODE_DISCOUNT: "CODE_DISCOUNT",
};
export const PromotionAppliesTo = {
  PRODUCT: "PRODUCT",
  OREDER: "ORDER",
};

export const DiscountMinimumRequirment = {
  NONE: "NONE",
  MINIMUM_PURCHASE: "MINIMUM_PURCHASE",
  MINIMUM_QUANTITY: "MINIMUM_QUANTITY",
};
export const COMMAND_ENABLED_STATE = [
  {
    label: "Nouvelle",
    value: "PENDING",
    states: ["PENDING", "APPROVED", "REVOKED", "READY"],
  },
  {
    label: "En cours",
    value: "APPROVED",
    states: ["APPROVED", "READY", "DELIVERED", "CANCELED", "DELIVERING"],
  },
  {
    label: "Prête",
    value: "READY",
    states: [
      "APPROVED",
      "READY",
      "DELIVERED",
      "REVOKED",
      "DELIVERED",
      "CANCELED",
    ],
  },
  {
    label: "Refusée",
    value: "REVOKED",
    states: ["PENDING", "APPROVED", "REVOKED", "READY"],
  },
  {
    label: "En livraison",
    value: "DELIVERING",
    states: [
      "APPROVED",
      "REVOKED",
      "READY",
      "DELIVERING",
      "DELIVERED",
      "CANCELED",
    ],
  },
  {
    label: "Annulée",
    value: "CANCELED",
    states: ["PENDING", "APPROVED", "REVOKED", "READY", "CANCELED"],
  },
  {
    label: "Terminée",
    value: "DELIVERED",
    states: ["APPROVED", "READY", "DELIVERING", "CANCELED", "DELIVERED"],
  },
];
export const navigationBreadcrumbs = (labels) => [
  {
    path: "/commandes",

    breadCrumbActive: labels?.pages?.commands?.commandList,
  },
  {
    path: "/command",
    goBack: true,
    breadCrumbParent: (
      <Link to="/commandes">{labels?.pages?.commands?.commandList}</Link>
    ),
    breadCrumbActive: labels?.pages?.commands?.detailCommandTitle,
  },
  {
    path: "/products",
    breadCrumbActive: labels?.pages?.products?.title,
  },
  {
    path: "/importProducts",
    goBack: true,
    breadCrumbParent: (
      <Link to="/productList">{labels?.pages?.products?.title}</Link>
    ),
    breadCrumbActive: labels?.pages?.products?.importProductsTitle,
  },
  {
    path: "/editProduct",
    goBack: true,
    breadCrumbParent: (
      <Link to="/productList">{labels?.pages?.products?.title}</Link>
    ),
    breadCrumbActive: labels?.pages?.products?.editProduct,
  },
  {
    path: "/addProduct",
    goBack: true,
    breadCrumbParent: (
      <Link to="/productList">{labels?.pages?.products?.title}</Link>
    ),
    breadCrumbActive: labels?.pages?.products?.addProduct,
  },
  {
    path: "/addons",
    breadCrumbActive: labels?.pages?.addons?.title,
  },
  {
    path: "/addAddon",
    goBack: true,
    breadCrumbParent: <Link to="/addons">{labels?.pages?.addons?.title}</Link>,
    breadCrumbActive: labels?.pages?.addons?.form?.title,
  },
  {
    path: "/editAddon",
    goBack: true,
    breadCrumbParent: <Link to="/addons">{labels?.pages?.addons?.title}</Link>,
    breadCrumbActive: labels?.pages?.addons?.form?.titleEdit,
  },
  {
    path: "/discount",
    breadCrumbActive: labels?.sidebar?.menu?.discount,
  },
  {
    path: "/addDiscount",
    goBack: true,
    breadCrumbParent: (
      <Link to="/discount">{labels?.sidebar?.menu?.discount}</Link>
    ),
    breadCrumbActive: labels?.pages?.promotions?.addDiscount,
  },
  {
    path: "/editDiscount",
    goBack: true,
    breadCrumbParent: (
      <Link to="/discount">{labels?.sidebar?.menu?.discount}</Link>
    ),
    breadCrumbActive: labels?.pages?.promotions?.editDiscount,
  },
  // #####clients
  {
    path: "/clients",
    breadCrumbActive: labels?.sidebar?.menu?.customersList,
  },

  {
    path: "/editClient",
    goBack: true,
    breadCrumbParent: (
      <Link to="/clients">{labels?.sidebar?.menu?.customersList}</Link>
    ),
    breadCrumbActive: labels?.pages?.clients?.updateClient,
  },
  // ###### delivery method
  {
    path: "/delivery",
    breadCrumbActive: labels?.sidebar?.menu?.deliveryMethod,
  },
  {
    path: "/editmail",
    breadCrumbParent: (
      <Link to="/settings">{labels?.sidebar?.menu?.settings}</Link>
    ),
    breadCrumbActive: labels?.pages?.settings?.templateMails,
  },
  {
    path: "/editpush",
    breadCrumbParent: (
      <Link to="/settings">{labels?.sidebar?.menu?.settings}</Link>
    ),
    breadCrumbActive: labels?.pages?.notification?.templateSubTitle,
  },
  {
    path: "/editsms",
    breadCrumbParent: (
      <Link to="/settings">{labels?.sidebar?.menu?.settings}</Link>
    ),
    breadCrumbActive: labels?.pages?.sms?.templateSubTitle,
  },
  {
    path: "/deliveryApi",
    goBack: true,
    breadCrumbParent: (
      <Link to="/delivery">{labels?.pages?.settings?.delivery}</Link>
    ),
    breadCrumbActive: labels?.pages?.delivery?.deliveryType,
  },
  {
    path: "/addDelivery",
    goBack: true,
    breadCrumbParent: (
      <Link to="/delivery">{labels?.pages?.settings?.delivery}</Link>
    ),
    breadCrumbParent2: (
      <Link to="/deliveryApi">{labels?.pages?.delivery?.deliveryType}</Link>
    ),
    breadCrumbActive: labels?.pages?.delivery?.addDelivery,
  },
  {
    path: "/editDelivery",
    goBack: true,
    breadCrumbParent: (
      <Link to="/delivery">{labels?.pages?.settings?.delivery}</Link>
    ),
    breadCrumbParent2: (
      <Link to="/deliveryApi">{labels?.pages?.delivery?.deliveryType}</Link>
    ),
    breadCrumbActive: labels?.pages?.delivery?.editDelivery,
  },
  {
    path: "/addDeliveryLocal",
    goBack: true,
    breadCrumbParent: (
      <Link to="/delivery">{labels?.pages?.settings?.delivery}</Link>
    ),

    breadCrumbActive: labels?.pages?.delivery?.addDriveDelivery,
  },
  {
    path: "/editDeliveryLocal",
    goBack: true,
    breadCrumbParent: (
      <Link to="/delivery">{labels?.pages?.settings?.delivery}</Link>
    ),

    breadCrumbActive: labels?.pages?.delivery?.editDelivery,
  },
  {
    path: "/deliveriesApi",

    breadCrumbActive: labels?.pages?.delivery?.deliveryType,
  },
  {
    path: "/AddDeliveryApi",
    goBack: true,
    breadCrumbParent: (
      <Link to="/deliveriesApi">{labels?.pages?.delivery?.deliveryType}</Link>
    ),
    breadCrumbActive: labels?.pages?.delivery?.addDeliveryType,
  },
  {
    path: "/editDeliveryApi",
    goBack: true,
    breadCrumbParent: (
      <Link to="/deliveriesApi">{labels?.pages?.delivery?.deliveryType}</Link>
    ),
    breadCrumbActive: labels?.pages?.delivery?.editDeliveryType,
  },
  {
    path: "/banner",
    breadCrumbActive: labels?.pages?.banner?.bannerTitle,
  },
  {
    path: "/faq",
    breadCrumbActive: labels?.faq?.faqC,
  },
  {
    path: "/FAQ-Client-And-Pro",
    breadCrumbActive: "FAQ",
  },

  {
    path: "/Mention-Legal-Client-And-Pro",
    breadCrumbActive: "À propos",
  },
  {
    path: "/ExternalLink-Client-And-Pro",
    breadCrumbActive: labels?.externalLink,
  },

  {
    path: "/Tabs-Templates",
    breadCrumbActive: "Templates",
  },
  {
    path: "/Tabs-Payment",
    breadCrumbActive: "Gestion de paiement",
  },
  {
    path: "/Tabs-Server-Mail-And-OneSignal",
    breadCrumbActive: "Serveur mail et notification push",
  },
  {
    path: "/Tabs-Professional",
    breadCrumbActive: "Parametres de professionnel",
  },

  {
    path: "/faqs-pro",
    breadCrumbActive: labels?.faq?.FaqPro,
  },
  {
    path: "/notification",
    breadCrumbActive: labels?.pages?.notification?.subSubTitle,
  },
  {
    path: "/addPushNotification",
    goBack: true,
    breadCrumbParent: (
      <Link to="/pages/pushNotifications/listNotifications">
        {labels?.pages?.notification?.subSubTitle}
      </Link>
    ),
    breadCrumbActive: labels?.pages?.notification?.addNotification,
  },
  {
    path: "/editPushNotification",
    goBack: true,
    breadCrumbParent: (
      <Link to="/pages/pushNotifications/listNotifications">
        {labels?.pages?.notification?.subSubTitle}
      </Link>
    ),
    breadCrumbActive: labels?.pages?.notification?.editNotification,
  },
  {
    path: "/payment",
    breadCrumbActive: labels?.pages?.payment?.title,
  },
  {
    path: "/options",
    breadCrumbActive: labels?.optionsList,
  },
  {
    path: "/tags",
    breadCrumbActive: labels?.tags,
  },
  {
    path: "/edit-option",
    goBack: true,
    breadCrumbParent: <Link to="/optionTemplates">{labels?.optionsList}</Link>,
    breadCrumbActive: labels?.editOption,
  },

  {
    path: "/pros",
    breadCrumbActive: labels?.pages?.stores?.subTitle,
  },
  {
    path: "/addPros",
    goBack: true,
    breadCrumbParent: <Link to="/pros">{labels?.pages?.stores?.subTitle}</Link>,
    breadCrumbActive: labels?.pages?.stores?.addStore,
  },
  {
    path: "/review-store",
    goBack: true,
    breadCrumbParent: <Link to="/pros">{labels?.pages?.stores?.subTitle}</Link>,
    breadCrumbActive: labels?.review,
  },
  {
    path: "/editPros",
    goBack: true,
    breadCrumbParent: <Link to="/pros">{labels?.pages?.stores?.subTitle}</Link>,
    breadCrumbActive: labels?.pages?.stores?.editStore,
  },
  {
    path: "/password",
    breadCrumbActive: labels?.pages?.stores?.editPassword,
  },
  {
    path: "/account",
    breadCrumbActive: labels?.pages?.stores?.editProfile,
  },
  {
    path: "/categories",
    breadCrumbActive: labels?.pages?.categories?.category,
  },
  {
    path: "/mentions",
    breadCrumbActive: labels?.sidebar?.menu?.mentionsLabels,
  },
  {
    path: "/groups",
    breadCrumbActive: labels?.pages?.group?.groups,
  },
  {
    path: "/add-group",
    breadCrumbParent: <Link to="/groups">{labels?.pages?.group?.groups}</Link>,
    breadCrumbActive: labels?.pages?.group?.addGroup,
  },
  {
    path: "/edit-group",
    breadCrumbParent: <Link to="/groups">{labels?.pages?.group?.groups}</Link>,
    breadCrumbActive: labels?.pages?.group?.editGroup,
  },
  {
    path: "/faq",

    breadCrumbActive: labels?.faq?.faqClient,
  },
  {
    path: "/contact",

    breadCrumbActive: labels?.contactus,
  },
  {
    path: "/faq-pro",

    breadCrumbActive: labels?.faq?.faqPro,
  },
  {
    path: "/mention-legal",
    breadCrumbActive: labels?.faq?.mentionLegal,
  },
  {
    path: "/mention-legal-pro",
    breadCrumbActive: labels?.faq?.mentionLegalP,
  },
  {
    path: "/notification-history",
    breadCrumbActive: labels?.pages?.notification?.pageSubTitle,
  },
  {
    path: "/commercials",
    breadCrumbActive: labels?.commercials,
  },
  {
    path: "/addCommercial",
    breadCrumbActive: labels?.addCommercial,
    goBack: true,
    breadCrumbParent: <Link to="/commercials">{labels?.commercials}</Link>,
  },
  {
    path: "/editCommercial",
    breadCrumbActive: labels?.editComemrcial,
    goBack: true,
    breadCrumbParent: <Link to="/commercials">{labels?.commercials}</Link>,
  },
  {
    path: "/structures",
    breadCrumbActive: labels?.structures,
    goBack: true,
    breadCrumbParent: <Link to="/commercials">{labels?.commercials}</Link>,
  },
  {
    path: "/addStructure",
    breadCrumbActive: labels?.addStructure,
    goBack: true,
    breadCrumbParent: <Link to="/structures">{labels?.structures}</Link>,
  },
  {
    path: "/editStructure",
    breadCrumbActive: labels?.editStructure,
    goBack: true,
    breadCrumbParent: <Link to="/structures">{labels?.structures}</Link>,
  },
];
// renderPageBreadcrumbs
