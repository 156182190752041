import gql from "graphql-tag";

const FRAGMENT_SETTINGS = `
 delivery {
  deliveriesApiKeys {
    stuart {
      apiClientId
      apiSecret
      token
      onlyByAdmin
      apiClientIdTest
      apiSecretTest
      tokenTest
    }
    yper {
      proId
      proSecretToken
      onlyByAdmin
    }
  }
}
stripe {
  publicKey
  privateKey
  publicTestKey
  privateTestKey
  status
  clientId
  secretEndpoint
}
paymee {
  publicKey
  privateKey
  publicTestKey
  privateTestKey
  status
}
paypal {
  publicKey
  privateKey
  status
  clientId
}
mailConfig {
  mailHost
  mailPort
  mailEncryption
  userName
  mailPassword
  senderEmail
  senderName
}
productsDocuments {
  required
  hidden
}
defaultStoreLocation {
  latitude
  longitude
  address
}
templateMail {
  header
  footer
}
oneSignalConfig {
  oneSignalClientAppId
  oneSignalClientRestApi
  oneSignalChannelId
  oneSignalChannelIdChat
  oneSignalChannelIdNotification

  oneSignalProId
  oneSignalProChannelId
  oneSignalProChannelIdChat
  oneSignalProChannelIdNotification
  oneSignalProRestApi
}

supportContact {
  supportTelClient
  supportEmailClient
  supportEmailPro
  supportTelPro
  supportMailSubscribeStore
}
appLinkStore{
  linkAppClientAndroid
  linkAppClientIOS
  linkAppProAndroid
  linkAppProIOS
  linkApkPro
  linkApkClient
}
isMarketPlace
showTelStoreInClientApp
showAddressStoreInClientApp
authorizeFreeDelivery
authorizeExpressDelivery
authorizeBannerAdsForStore
paymentCash
paymentStripe
paymentPaypal
paymentPaymee
stripeRequired
paypalRequired
cashRequired
authorizePaymee
paymeeRequired
appName
appLogo
appResponsiveLogo
authorizeChatDirect
authorizeDeleteSubCommentNotice
authorizeDeleteNotice
authorizeNoticeStore
auhorizeNoticeAutomaticly
limitNoticeSubComments
authorizeNoticeProduct
authorizeReplayOtherNotice
updateOrderPriceWhenUpdateOrderProducts
uniqueStore
authorizeCopyProductOnImport
authorizeDuplicateProducts
authorizeApkLink
defaultStorePassword
authorizeStuart
authorizeYper
authorizeVerifyInscription

authorizeUpdateMentionLegal
authorizeShowGroupsInClientApp
authorizeShowTypesInClientApp
authorizePrinter
authorizeStripeConnect
authorizeAuthentificationFacebook
authorizeGroup
authorizeMenageParticular
authorizeTags
authorizeStoreLabels
showEan
showRef
authorizeCreateProductStore
authorizeAddons
authorizeOptions
authorizeStoreDiscount
authorizeStoreMarketing
authorizeEditPriceImportedNotDuplicatedProduct
deliveryApiEnabled
orderLifeTime
stuartEnabled
yperEnabled
requirePhotoProfile
showAlertMessageProduct
alertProductMessage
defaultStorePhoto
authorizeMenageFeeStripe
authorizeMenageFeeService
country {
  id
  name
  code
  phone
  language
  currency
  currencyCode
  emoji
}
`;
const FRAGMENT_SETTINGS_RENUMERATION = `
renumeration {
  feeStripe {
    status
    defaultApplicablePrice
  }
  feeHosting {
    status
    defaultApplicablePrice
    acompteRequest {
      status
      acompte
      solde
    }
    exceptions {
      structure
      fee
    }
  }
  feeSubscription {
    status
    month {
      status
      defaultApplicablePrice
      exceptions {
        structure
        fee
      }
      dueDate
    }
    trimester {
      status
      defaultApplicablePrice
      exceptions {
        structure
        fee
      }
      dueDate
    }
    annual {
      status
      defaultApplicablePrice
      exceptions {
        structure
        fee
      }
      dueDate
    }
  }
  feeCustomerOrder {
    applyCommissionCartAmount {
      status
      fee
      feeType
    }
    applyCommissionDeliveryFee {
      status
      fee
      feeType
    }
    applyCommissionServiceFee {
      status
      fee
      feeType
    }
  }
  feePartnerGains {
    gainStoreSubscribeFee {
      status
      fee
    }
    gainOnServiceHostingFee {
      status
      fee
      feeType
      exceptions {
        structure
        fee
      }
    }
    gainSubscriptionFee {
      status
      fee
      feeType
      exceptions {
        structure
        fee
      }
    }
    gainOrderCustomerFee {
      status
      fee
      feeType
      exceptions {
        structure
        fee
      }
    }
  }
  feeService {
    status
    fee
    feeType
  }

}
`;
const FRAGMENT_PART_SETTINGS = `
authorizeGroup
showEan
showRef
authorizeCreateProductStore
authorizeAddons
authorizeOptions
authorizeStoreDiscount
authorizeStoreMarketing
authorizeEditPriceImportedNotDuplicatedProduct
showAlertMessageProduct
alertProductMessage
authorizeMenageParticular
`;
export const ADD_COUNTRY = gql`
  mutation addCountry($input: InputCountry) {
    addCountry(input: $input) {
      id
      name
      code
      phone
      language
      currency
      currencyCode
      emoji
    }
  }
`;

export const DELETE_COUNTRY = gql`
  mutation deleteCountry($id: ID) {
    deleteCountry(id: $id) {
      id
      name
      code
      phone
      language
      currency
    }
  }
`;
export const GET_COUNTRIES = gql`
  query {
    getCountries {
      id
      name
      code
      phone
      language
      currency
      currencyCode
      emoji
    }
  }
`;
export const UPDATE_SETTINGS = gql`
 mutation updateDeliveryApiSetting(
    $api: String,
    $input: DeliveryApiKeyInput,
    $inputYper: DeliveryYperApiKeyInput
  ){
updateDeliveryApiSetting(api:$api,input:$input,inputYper:$inputYper){
  ${FRAGMENT_SETTINGS}
}
  }
`;
export const UPDATE_PAYMENT_KEYS = gql`
  mutation updatePaymentKeysApi($input: PaymentApiKeysInput) {
    updatePaymentKeysApi(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_SETTINGS_PARAMS = gql`
  mutation updateSettingsParams($input: ParamsInput!) {
    updateSettingsParams(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_GLOBAL_SETTINGS = gql`
  mutation updateGlobalSettings($input: GlobalInput) {
    updateGlobalSettings(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_TEMPLATE_MAIL_SETTINGS = gql`
  mutation updateMailTemplateCore($input: TemplateMailCoreInput) {
    updateMailTemplateCore(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_ONE_SIGNAL_SETTINGS = gql`
  mutation updateOneSignalConfig($input: OneSignalConfigInput) {
    updateOneSignalConfig(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_CONTACT_SUPPORT_SETTINGS = gql`
  mutation updateSupportContact($input: SupportContactInput) {
    updateSupportContact(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_APP_LINK_STORE_SETTINGS = gql`
  mutation updateAppStoreLink($input: AppLinkStoreInput) {
    updateAppStoreLink(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const GET_APP_SETTINGS = gql`
  query {
    getSettings {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const GET_RENUMERATION_SETTINGS = gql`
  query {
    getSettings {
      ${FRAGMENT_SETTINGS_RENUMERATION}
    }
  }
`;
export const GET_PART_SETTINGS = gql`
  query {
    getSettings {
      ${FRAGMENT_PART_SETTINGS}
    }
  }
`;
export const UPDATE_RENUMERATION_SETTINGS = gql`
  mutation updateRenumerationSettings($input: RenumerationInput) {
  updateRenumerationSettings(input: $input) {
      ${FRAGMENT_SETTINGS_RENUMERATION}
    }
  }
`;
export const GET_STEPS_SETTINGS = gql`
  query {
    getProfileCompletedConfiguration {
      isDeliveryModeCompleted
      isPaymentMethodCompleted
      isCategoryCompleted
      isProductCompleted
      isProfileCompleted
      completedProfilePourcent
      isFullCompleted
      isResponsibleCompleted
      isEntrepriseCompleted
      isFeeSubscriptionCompleted
      isFeeHostingCompleted
    }
  }
`;
export const GET_STEPS_SETTINGS_ADMIN = gql`
  query getProfileCompletedConfiguration($store: String) {
    getProfileCompletedConfiguration(store: $store) {
      isDeliveryModeCompleted
      isPaymentMethodCompleted
      isCategoryCompleted
      isProductCompleted
      isProfileCompleted
      completedProfilePourcent
      isFullCompleted
      isResponsibleCompleted
      isEntrepriseCompleted
      isFeeSubscriptionCompleted
      isFeeHostingCompleted
    }
  }
`;
export const GET_SETTINGS = gql`
  query {
    myStore {
      id
      settings {
        paymentCash
        paymentPaypal
        paymentStripe
      }
    }
  }
`;
